import { fetchPipe } from "@/api/model";

export default {

    data() {
        return {
            pipeList: [],
            pipeDetail: {},
            pipeVisible: false,
        }
    },

    methods: {
        getPipe(year) {

            if (this.pipeList.length === 0) {
                fetchPipe({
                    type: "rain",
                }).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        if (year) {
                            this.addPipeLine({ list: Object.freeze(res.locs) });
                        } else {
                            this.addPipeLine({ list: Object.freeze(res.locs.filter((item, index) => index < 1000)) });
                        }

                    }
                });
            } else {
                // 已经有值，直接显示出来即可
                this.pipeList.forEach(item => {
                    item.show();
                })
            }
        },

        addPipeLine({ list = [], roundLine = true }) {
            if (!this.map) {
                return;
            }

            const { AMap } = window;

            const arr = [];

            list.forEach(item => {

                const path = [
                    [item[1], item[2]],
                    [item[3], item[4]],
                ];

                // 创建折线实例
                var polyline = new AMap.Polyline({
                    path,
                    strokeWeight: 8, // 线条宽度，默认为 1
                    strokeColor: "#f00", // 线条颜色
                    lineJoin: roundLine ? "round" : "miter", // 折线拐点连接处样式
                    // showDir,
                    extData: {
                        id: item[0]
                    },
                });

                // polyline.on("click", this.onPipeClick);

                // 将折线添加至地图实例
                this.map.add(polyline);
                arr.push(polyline);

            })

            // this.pipeList = Object.freeze(arr);
        },

        onPipeClick(e) {
            console.log("e", e);
            this.pipeDetail = e.target._opts.extData;
            this.pipeVisible = true;
            this.drainVisible = false;
            this.wellVisible = false;
        },

        removePipe() {
            this.pipeVisible = false;
            this.pipeList.forEach(item => {
                item.hide();
            })
        },

    }
}

