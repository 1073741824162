<template>
  <div>
    <div id="map1"></div>

    <div class="year">
      <a-icon type="calendar" />
      <span>2021</span>
    </div>
  </div>
</template>
<script>
import pipe from "./mixins/pipe";
export default {
  mixins: [pipe],

  data() {
    return {};
  },

  mounted() {
    this.initMap();
  },

  beforeDestroy() {
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map1", {
        center: [112.761379, 32.135151],
        zoom: 15,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });
      // AMap.plugin(["AMap.Scale", "AMap.HawkEye"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      // });

      this.map = map;

      this.getPipe();
    },
  },
};
</script>
<style lang="less" scoped>
#map1 {
  position: fixed;
  top: -50px;
  left: 0;
  width: 50vw;
  bottom: -20px;
  z-index: 11;
  border-right: 1px solid #999;
}

.year {
  position: fixed;
  top: 11vh;
  left: 44vw;
  z-index: 99;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  padding: 0 0.8vw;
  box-sizing: border-box;
  height: 4vh;
  line-height: 4vh;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  font-size: 1.4vh;
  cursor: pointer;
  span {
    margin-left: 0.4vw;
  }
}
</style>