<template>
  <div id="chart"></div>
</template>


<script>
import * as echarts from "echarts/core";
import { GridComponent, LegendComponent } from "echarts/components";
import { LineChart, BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  GridComponent,
  LegendComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
]);

export default {
  data() {
    return {
      list: [
        {
          date: "00:00",
          value1: 4300,
          value2: 200,
        },
        {
          date: "01:00",
          value1: 5300,
          value2: 300,
        },
        {
          date: "02:00",
          value1: 4100,
          value2: 280,
        },
        {
          date: "03:00",
          value1: 4900,
          value2: 130,
        },
        {
          date: "04:00",
          value1: 4300,
          value2: 200,
        },
        {
          date: "05:00",
          value1: 4300,
          value2: 400,
        },
      ],
    };
  },

  mounted() {
    var chartDom = document.getElementById("chart");
    this.chart = echarts.init(chartDom);
    this.initChart(this.list);
  },

  methods: {
    initChart(list = []) {
      var option = {
        legend: { textStyle: { color: '#fff' },
          left: "center",
          top: "bottom",
          data: ["液位计", "流量计"],
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          valueFormatter: (value) => value + "m³/s",
        },

        xAxis: {
          axisLine: {
            lineStyle: {
              color: "#999",
            },
          },

          splitLine: {
            lineStyle: {},
          },
          type: "category",
          data: list.map((item) => {
            return {
              value: item.date,
            };
          }),
        },
        grid: {
          left: "4%",
          right: "2%",
          top: "2%",
          bottom: "10%",
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              lineStyle: {},
            },
          },
          {
            type: "value",
            splitLine: {
              lineStyle: {},
            },
          },
        ],
        series: [
          {
            name: "液位计",
            data: list.map((item) => item.value2),
            type: "bar",
            label: {
              show: true,
            },
          },
          {
            name: "流量计",
            data: list.map((item) => item.value1),
            type: "line",
            smooth: true,
            label: {
              show: true,
            },
          },
        ],
      };

      option &&
        this.chart &&
        this.chart.setOption &&
        this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
#chart {
  margin-top: 2vh;
  background-color: var(--bg-color);
  height: 70vh;
  width: 100%;
}
</style>