import request from '../request'



export function fetchPipe(data) {
    return request({
        url: '/model-analysis/pipe/locations',
        method: 'post',
        data: {
            ...data,
            district: 'zaoyang'
        }
    })
}

export function fetchPipeDetail(data) {
    return request({
        url: '/model-analysis/pipe/query',
        method: 'post',
        data
    })
}


// 获取污水收集区列表
export function fetchSewageScope(data) {
    return request({
        url: '/model-analysis/pipe/area/locations',
        method: 'post',
        data: {
            ...data,
            district: 'zaoyang'
        }
    })
}

export function fetchWell(data) {
    return request({
        url: '/model-analysis/pipe/well/locations',
        method: 'post',
        data: {
            ...data,
            district: 'zaoyang'
        }
    })
}

export function fetchWellDetail(data) {
    return request({
        url: '/model-analysis/pipe/well/query',
        method: 'post',
        data: {
            ...data,
            district: 'zaoyang'
        }
    })
}


// 获取每条河的经纬度
export function fetchRiverDetail(data) {
    return request({
        url: '/model-analysis/pipe/area/locations',
        method: 'post',
        data: {
            ...data,
            district: 'zaoyang'
        }
    })
}

// 获取所有的河
export function fetchRiverList(data) {
    return request({
        url: '/model-analysis/pipe/area',
        method: 'post',
        data
    })
}

// 获取所有的河
export function fetchWaterFacilityList(data) {
    return request({
        url: '/model-analysis/pipe/area/devices',
        method: 'post',
        data
    })
}
