<template>
  <div class="selector">
    <div class="header">数据中心</div>
    <div class="query">
      <a-form-model layout="inline" :colon="false">
        <a-form-model-item>
          <a-radio-group button-style="solid" v-model="form.dateType">
            <a-radio-button value="日">近一天</a-radio-button>
            <a-radio-button value="月">近三天</a-radio-button>
            <a-radio-button value="年">近一周</a-radio-button>
            <a-radio-button value="自定义">自定义</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.dateType === '自定义'">
          <a-range-picker v-model="date" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <Chart />
  </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
  components: {
    Chart,
  },

  data() {
    return {
      form: {},
      date: null,
    };
  },
};
</script>

<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  bottom: 2vh;
  left: 17vw;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  box-sizing: border-box;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .query {
    padding: 0.8vw 1vw;
  }
}
</style>
