<template>
  <div>
    <div id="map"></div>

    <Selector
      :activeIndex="activeIndex"
      @change="(payload) => (activeIndex = payload)"
    />

    <DataCenter v-if="activeIndex === 0" />
    <History v-if="activeIndex === 1" />
  </div>
</template>
<script>
import area from "@/mixins/area";
import Selector from "./components/selector.vue";
import DataCenter from "./components/data-center";
import History from "./components/history";

export default {
  mixins: [area],

  components: {
    Selector,
    DataCenter,
    History,
  },

  data() {
    return {
      activeIndex: 0,

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [
        {
          no: "2020-12-21 00:00:00",
          no1: "物理法",
          no2: 7.18,
          no3: 23.8,
          no4: 1784.4,
          no5: 200.5,
          no6: 732.0,
          no7: 120.64,
          no8: -331.0,
          no9: 1.9,
        },
        {
          no: "2020-12-21 00:00:00",
          no1: "物理法",
          no2: 7.18,
          no3: 23.8,
          no4: 1784.4,
          no5: 200.5,
          no6: 732.0,
          no7: 120.64,
          no8: -331.0,
          no9: 1.9,
        },
        {
          no: "2020-12-21 00:00:00",
          no1: "物理法",
          no2: 7.18,
          no3: 23.8,
          no4: 1784.4,
          no5: 200.5,
          no6: 732.0,
          no7: 120.64,
          no8: -331.0,
          no9: 1.9,
        },
        {
          no: "2020-12-21 00:00:00",
          no1: "物理法",
          no2: 7.18,
          no3: 23.8,
          no4: 1784.4,
          no5: 200.5,
          no6: 732.0,
          no7: 120.64,
          no8: -331.0,
          no9: 1.9,
        },
      ],
      total: 0,
    };
  },

  mounted() {
    this.initMap();
    this.renderDistrict();
  },

  beforeDestroy() {
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 15,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });
      // AMap.plugin(["AMap.Scale", "AMap.HawkEye"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      // });

      this.map = map;
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 10;
}
</style>