<template>
  <div>
    <Map1 />
    <Map2 />
  </div>
</template>

<script>
import Map1 from "./map1.vue";
import Map2 from "./map2.vue";
export default {
  components: {
    Map1,
    Map2,
  },
};
</script>